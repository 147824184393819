import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Actions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";

import AuthTitles from "../commons/auth-titles";
import AuthForm from "../commons/auth-form";
import AuthInput from "../commons/auth-input";
import AuthSubmitButton from "../commons/auth-submit-button";
import { FaRegEnvelope } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa6";

const Login = ({ actions, isLoggedIn, status }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const handleLogin = (data) => {
        actions.loginUser(data);
    }
    const loginOptions = {
        email: {
            required: "Email is required"
        },
        password: {
            required: "Password is required",
        }
    };

    return (
        <>
            <AuthTitles
                title="Login to your account"
                subtitle="Welcome back!"
            />

            <AuthForm className={'p-5'} onSubmit={handleSubmit(handleLogin)}>
                <AuthInput
                    iconBefore={<FaRegEnvelope size={20}/>}
                    iconBeforeBtn={false}
                    iconAfter={false}
                    iconAfterBtn={false}
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                    reg={{ ...register('email', loginOptions.email) }}
                />
                <small className="block mb-3 text-[#8a0000]">
                    {errors?.email && errors.email.message}
                </small>
                <AuthInput
                    iconBefore={<MdOutlineSecurity size={22}/>}
                    iconBeforeBtn={false}
                    iconAfter={<FaRegEyeSlash size={20}/>}
                    iconAfterBtn={true}
                    iconAfterBtnType={"showField"}
                    placeholder="Password"
                    type={"password"}
                    name="password"
                    autoComplete="new-password"
                    reg={{ ...register('password', loginOptions.password) }}
                />
                <small className="block mb-3 text-[#8a0000]">
                    {errors?.password && errors.password.message}
                </small>
                <div className={'mb-4 grid grid-cols-12'}>
                    <div className="lg:col-span-6 col-span-12">
                        <div className={'mb-0 text-left'}>
                            <input type="checkbox" className={'hidden form-check-input'} name="remember_me" value="1" id="remember_me" />
                            <label className={'mb-0 form-label'} htmlFor="remember_me">Remember me</label>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 text-end">
                        <Link className={'text-decoration-none font-semibold'} to="/forgot-password">Forgot Password?</Link>
                    </div>
                </div>
                <AuthSubmitButton id="loginForm" text="Login" />
                <p>Don't have account? <Link className={'font-bold text-decoration-none'} to="/signup">Create an account</Link>.</p>
            </AuthForm>

            <div></div>
        </>
    );
}

const mapStateToProps = (props) => ({
    isLoggedIn: props.userReducer.isLoggedIn,
    status: props.userReducer.status,
    user: props.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
