const AuthForm = (props) => {
    return (
        <>
            <form onSubmit={props.onSubmit} className={'auth-form mx-auto w-[350px] ' + props.className}>
                {props.children}
            </form>
        </>
    );
}

export default AuthForm;