const AuthSubmitButton = (props) => {
    const submitForm = (id) => {
    }

    return (
        <>
            <button className={'submit-btn rounded-md w-full text-white mb-2 bg-blue-600 border-0 uppercase text-lg font-semibold h-[50px] ' + props.className} variant="primary" onClick={() => submitForm(props.id)}>
                {props.text}
            </button>

        </>
    );
}

export default AuthSubmitButton;