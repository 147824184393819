import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import OffCanvasMenu from "../sidebar/off-canvas-menu";

const DashboardLayout = (props) => {
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const [contentFull, setContentFull] = useState(false);
	const handleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
		setContentFull(!contentFull);
	};

	const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

	return (
		<>
			<div className={"h-screen"}>
				<div className={"h-full flex flex-wrap relative"}>
					<div className={'dashboard-layout--sidebar hidden lg:block w-[20vw] fixed h-screen border-r-2 border-[#E5EAEF] ' + (sidebarOpen ? "open" : "closed")}>
						<Sidebar toggleSidebar={handleSidebar} />
					</div>
					<div className={'dashboard-layout--content p-4 w-full lg:w-[78vw] lg:left-[20vw] absolute ' + (contentFull ? "full" : "half")}>
						{/* <OffCanvasMenu onClick={toggleMenu} isOpen={isOpen} /> */}
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}

export default DashboardLayout;