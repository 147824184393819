import { useState } from "react";
import { FaRegEye } from "react-icons/fa6";

const AuthInput = (props) => {
	const [toggleField, setToggleField] = useState(props.type);
	const toggleBtn = (event) => {
		event.preventDefault();
		event.stopPropagation()
		setToggleField(toggleField === "password" ? "text" : "password")
	}

	return (
		<>
			<div className="mb-2 px-2 input-group flex flex-row items-center relative border border-black rounded-md">
				{
					props.iconBefore ? (
						<>
							{props.iconBefore}
						</>
					) : ""
				}
				<input
					className={"w-full p-2 border-0 text-sm bg-transparent outline-0 shadow-none height-[50px] " + props.className}
					placeholder={props.placeholder}
					type={toggleField}
					name={props.name}
					autoComplete={props.autoComplete}
					{...props.reg ? props.reg : ""}
				/>
				{
					props.iconAfterBtn ? (
						<>
							{
								props.iconAfterBtnType === "showField" ?
									(
										<button onClick={(event) => toggleBtn(event)}>
											{
												props.iconAfter ? <FaRegEye size={20} /> : "btn"
											}
										</button>
									)
									: ""
							}
						</>
					) : (
						props.iconAfter ? props.iconAfter : ""
					)
				}
			</div>
		</>
	);
}

export default AuthInput;