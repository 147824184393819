import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const AuthLayout = (props) => {
	const navigate = useNavigate();
	useEffect(() => {
		checkLogin();
	}, []);
	useEffect(() => {
		checkLogin();
	}, [props]);
	const checkLogin = () => {
		if (props.isLoggedIn) {
			navigate('/dashboard', { replace: true });
		}
	}
	return (
		<>
			<div className={"h-screen"}>
				<div className={"h-full grid grid-cols-12"}>
					<div className={'hidden lg:flex lg:col-span-9 col-span-12 flex-col justify-between text-center'}>
						<img className={'w-full h-screen object-cover'} src="/assets/images/login-banner-2.webp" alt="banner" />
					</div>

					<div className={"lg:col-span-3 col-span-12 auth-pages--form-side bg-white-500 flex justify-center lg:mt-5 lg:items-center flex-column lg:justify-start lg:items-start lg:flex-row"}>
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}

export default AuthLayout;