import { DEV_URL } from "../constants/config";

const request = async (url, method, payload, auth, dataType) => {
    const options = {
        method: method ? method : 'GET',
        headers: {
            "Content-Type": "application/json"
        },
    };

    const token = localStorage.getItem('token');
    if (auth && token) {
        options.headers['Authorization'] = "Bearer " + token;
    }
    console.log(payload, "payload")
    if (payload) {
        options.body = JSON.stringify(payload);
    }

    const apiUrl = `${DEV_URL}${url}`;
    console.log('apiUrl', apiUrl);
    try {
        //dispatch({ type: IS_LOADING, payload: true });
        let res = await fetch(
            apiUrl,
            options
        );
        let data = await res.json();
        console.log(data);
        if (data.status === "ok") {
            //dispatch({ type: IS_LOADING, payload: false });
        } else {
            //dispatch({ type: IS_LOADING, payload: false });
            console.log(res.error);
        }
        return data;
    } catch (error) {
        console.log(error);
    }
};

export { request };