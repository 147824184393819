import { Link } from "react-router-dom";
import Menu from "./menu";
const Sidebar = (props) => {
    return (
        <>
            <button onClick={props.toggleSidebar} className="sidebar-toggle hidden absolute w-[50px] h-[50px] leading-[50px] text-center rounded-full bg-st-pink top-[80px] right-[-25px] z-[1]">
                ☰
            </button>
            <div className="p-4">
                <Link href={"dashboard/"}>
                    <img className="mx-auto block" src='./../logo.png' alt="logo" width="120" height="120" />
                </Link>
            </div>
            <div className="menu-row flex justify-center overflow-y-auto">
                <Menu />
            </div>
        </>
    );
}

export default Sidebar;