import {
    LOGIN_SUCCESS, LOGIN_FAILURE,
    SIGNUP_SUCCESS, SIGNUP_FAILURE,
    AUTHENTICATE_USER_SUCCESS, AUTHENTICATE_USER_FAILURE,
    LOGOUT_SUCCESS
} from "../../constants/action-types";

const initialState = {
    user: {},
    isLoggedIn: localStorage.getItem('token') ? true : false,
    status: null,
};

const userReducer = (state = initialState, action) => {
    if (action.type === LOGIN_SUCCESS) {
        return {
            ...state,
            isLoggedIn: true,
            user: action.data,
            status: LOGIN_SUCCESS
        };
    }
    if (action.type === LOGIN_FAILURE) {
        return {
            ...state,
            isLoggedIn: false,
            user: null,
            status: LOGIN_FAILURE
        }
    }
    if (action.type === SIGNUP_SUCCESS) {
        return {
            ...state,
            isLoggedIn: true,
            user: action.data,
            status: SIGNUP_SUCCESS
        };
    }
    if (action.type === SIGNUP_FAILURE) {
        return {
            ...state,
            isLoggedIn: false,
            user: null,
            status: SIGNUP_FAILURE
        }
    }
    if (action.type === AUTHENTICATE_USER_SUCCESS) {
        return {
            ...state,
            user: action.data,
            isLoggedIn: true,
            status: AUTHENTICATE_USER_SUCCESS
        };
    }
    if (action.type === AUTHENTICATE_USER_FAILURE) {
        return {
            ...state,
            isLoggedIn: true,
            status: AUTHENTICATE_USER_FAILURE
        }
    }
    if (action.type === LOGOUT_SUCCESS) {
        return {
            ...state,
            isLoggedIn: false,
            user: {},
            status: LOGOUT_SUCCESS
        }
    }
    return state;
}

export default userReducer;
