import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Actions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";

import AuthTitles from "../commons/auth-titles";
import AuthForm from "../commons/auth-form";
import AuthInput from "../commons/auth-input";
import AuthSubmitButton from "../commons/auth-submit-button";
import { IoPersonOutline } from "react-icons/io5";
import { FaRegEnvelope } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";

const Signup = ({ actions, isLoggedIn, status }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const handleLogin = (data) => {
        console.log(data, "dataa");
        actions.signupUser(data);
    }
    const loginOptions = {
        name: {
            required: "Name is required"
        },
        email: {
            required: "Email is required"
        },
        password: {
            required: "Password is required",
        },
        confirm_password: {
            required: "Confirm Password is required",
        },
        // accept: {
        //     required: "Please accept terms and conditions",
        // }
    };
    return (
        <>
            <AuthTitles
                title="Signup to your account"
                subtitle="Welcome back!"
            />

            <AuthForm className={'auth-signup-form p-5'} onSubmit={handleSubmit(handleLogin)}>
                <AuthInput
                    iconBefore={<IoPersonOutline size={20}/>}
                    iconBeforeBtn={false}
                    iconAfter={false}
                    iconAfterBtn={false}
                    placeholder="Username"
                    type="text"
                    name="name"
                    autoComplete="new-text"
                    reg={{ ...register('name', loginOptions.name) }}
                />
                <small className="block mb-3 text-[#8a0000]">
                    {errors?.name && errors.name.message}
                </small>
                <AuthInput
                    iconBefore={<FaRegEnvelope size={20}/>}
                    iconBeforeBtn={false}
                    iconAfter={false}
                    iconAfterBtn={false}
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                    reg={{ ...register('email', loginOptions.email) }}
                />
                <small className="block mb-3 text-[#8a0000]">
                    {errors?.email && errors.email.message}
                </small>
                <AuthInput
                    iconBefore={<MdOutlineSecurity size={22}/>}
                    iconBeforeBtn={false}
                    iconAfter={'/assets/images/icons/eye-slash.png'}
                    iconAfterBtn={true}
                    iconAfterBtnType={"showField"}
                    placeholder="Password"
                    type={"password"}
                    name="password"
                    autoComplete="new-password"
                    reg={{ ...register('password', loginOptions.password) }}
                />
                <small className="block mb-3 text-[#8a0000]">
                    {errors?.password && errors.password.message}
                </small>
                <AuthInput
                    iconBefore={<MdOutlineSecurity size={22}/>}
                    iconBeforeBtn={false}
                    iconAfter={'/assets/images/icons/eye-slash.png'}
                    iconAfterBtn={true}
                    iconAfterBtnType={"showField"}
                    placeholder="Confirm Password"
                    type={"password"}
                    name="confirm_password"
                    autoComplete="new-comfirm-password"
                    reg={{ ...register('confirm_password', loginOptions.confirm_password) }}
                />
                <small className="block mb-3 text-[#8a0000]">
                    {errors?.confirm_password && errors.confirm_password.message}
                </small>
                <div className={'mb-4'}>
                    <div className={'mb-0 text-left'}>
                        <input type="checkbox" className={'hidden form-check-input'} name="accept" value="1" id="accept" reg={{ ...register('accept', loginOptions.accept) }} />
                        <label className={'mb-0 form-label'} htmlFor="accept">Accept terms and conditions</label>
                        <small className="block mb-3 text-[#8a0000]">
                            {errors?.accept && errors.accept.message}
                        </small>
                    </div>
                </div>
                <AuthSubmitButton text="Signup" />

                <p>You have account? <Link className={'font-bold text-decoration-none'} to="/login">Login now</Link>.</p>
            </AuthForm>

            <div></div>
        </>
    );
}

const mapStateToProps = (props) => ({
    isLoggedIn: props.userReducer.isLoggedIn,
    status: props.userReducer.status,
    user: props.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);