import React from "react";
import { Link } from "react-router-dom";
import AuthTitles from "../commons/auth-titles";
import AuthForm from "../commons/auth-form";
import AuthInput from "../commons/auth-input";
import AuthSubmitButton from "../commons/auth-submit-button";
import { FaEnvelope } from "react-icons/fa6";

const ForgotPassword = (props) => {

    return (
        <>
            <AuthTitles
                title="Forgot Password?"
                subtitle="Enter your email!"
            />

            <AuthForm className={'auth-forgot-password-form p-5'}>
                <AuthInput
                    iconBefore={<FaEnvelope size={20}/>}
                    iconBeforeBtn={false}
                    iconAfter={false}
                    iconAfterBtn={false}
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                />
                <AuthSubmitButton text="Forgot Password" />

                <p>You have account? <Link className={'font-bold text-decoration-none'} to="/login">Login now</Link>.</p>
            </AuthForm>

            <div></div>
        </>
    );
}

export default ForgotPassword;