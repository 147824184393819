import { Navigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { connect } from "react-redux";

import AuthProtected from "./AuthProtected";
import RoleProtected from "./RoleProtected";

import AuthLayout from "../components/Layouts/AuthLayout";
import DashboardLayout from "../components/Layouts/DashboardLayout";

import Login from "../components/login/login";
import Signup from "../components/signup/signup";
import Logout from "../components/logout/logout";
import ForgotPassword from "../components/forgot-password/forgot-password";

import Dashboard from '../components/dashboard/dashboard';

const AppRoutes = ({ actions, isLoggedIn, status }) => {
	let user = {
		name: "Dev",
		role: "admin",
		loggedIn: true,
	};
	console.log(isLoggedIn, "poopop")
	return (
		<>
			<Router>
				<Routes>
					<Route element={<AuthLayout isLoggedIn={isLoggedIn} />}>
						<Route path="/" element={<Navigate to={"/login"} />} />
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/logout" element={<Logout />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
					</Route>

					<Route element={<AuthProtected user={user} isLoggedIn={isLoggedIn} />}>
						<Route element={<DashboardLayout />}>
							<Route path="/dashboard" element={<Navigate to={"/dashboard/home"} />} />
							<Route path="/dashboard/home" element={<Dashboard />} />
						</Route>
					</Route>
				</Routes>
			</Router>
		</>
	);
}


const mapStateToProps = (props) => ({
	isLoggedIn: props.userReducer.isLoggedIn,
	status: props.userReducer.status,
	user: props.userReducer.user,
});


export default connect(mapStateToProps)(AppRoutes);