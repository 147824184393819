import { toast } from "react-toastify";

export const successAlert = (msg) => {
    console.log(msg)
    toast.success(msg);
};
export const errorAlert = (error) => {
    toast.error(error);
};
export const formateDate = (date, formate) => {
    const dateObject = new Date(date);
    if (isNaN(dateObject.getTime())) {
        return null; // or handle invalid dates as needed
    }

    let formattedDate = null;
    if (formate === 'MM/DD/YYYY') {
        formattedDate = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
    } else if (formate === 'MM-DD-YYYY') {
        formattedDate = `${dateObject.getMonth() + 1}-${dateObject.getDate()}-${dateObject.getFullYear()}`;
    } else if (formate === 'DD/MM/YYYY') {
        formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    } else if (formate === 'DD-MM-YYYY') {
        formattedDate = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
    }

    return formattedDate;
}

export const reactSelectCustomStype = {
    control: (provided) => ({
        ...provided,
        border: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 5,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
};

export const addCustomFieldSelectCustomStyles = {
    control: (provided) => ({
        ...provided,
        border: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 5,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,  // Set a high z-index for the select menu
    }),
};

export const ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}