import React from "react";
import { Link } from "react-router-dom";
import AuthTitles from "../commons/auth-titles";

const Logout = (props) => {
    return (
        <>
            <AuthTitles
                title="LogOut"
                subtitle="Lost the Session!"
            />

            <div className={'mx-auto w-[350px] uppercase text-xl font-semibold'}>
                <div xs={12}>
                    <Link className={'logout-links--a text-white rounded-md w-full block bg-st-blue border-0 h=[50px] leading-[50px] mb-4'} to="/login">Login</Link>
                </div>
                <div xs={12}>
                    <Link className={'logout-links--a text-white rounded-md w-full block bg-st-blue border-0 h=[50px] leading-[50px] mb-0'} to="/signup">Signup</Link>
                </div>
            </div>

            <div></div>
        </>
    );
}

export default Logout;