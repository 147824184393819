import { NavLink } from "react-router-dom";
import {
  MdDashboard,
  MdInventory,
  MdLocalShipping,
  MdSchedule,
  MdSettings,
  MdGroup,
  MdSupport,
  MdAssessment,
  MdStore,
  MdListAlt,
} from "react-icons/md";

const Menu = () => {
  const menuItems = [
    {
      link: "dashboard/home",
      icon: MdDashboard,
      text: "Dashboard",
    },
    {
      link: "dashboard/inventory",
      icon: MdInventory,
      text: "Inventory",
    },
    {
      link: "dashboard/orders",
      icon: MdListAlt,
      text: "Orders",
    },
    {
      link: "dashboard/suppliers",
      icon: MdGroup,
      text: "Suppliers",
    },
    {
      link: "dashboard/shipping-status",
      icon: MdLocalShipping,
      text: "Shipping Status",
    },
    {
      link: "dashboard/schedule",
      icon: MdSchedule,
      text: "Schedule",
      gap: true,
    },
    {
      link: "dashboard/settings",
      icon: MdSettings,
      text: "Settings",
    },
    {
      link: "dashboard/members",
      icon: MdGroup,
      text: "Members",
    },
    {
      link: "dashboard/customer-support",
      icon: MdSupport,
      text: "Customer Support",
      gap: true,
    },
    {
      link: "dashboard/reports",
      icon: MdAssessment,
      text: "Reports",
    },
  ];

  return (
    <ul className="">
      {menuItems.map((item, index) => (
        <GenerateLink key={index} data={item} />
      ))}
    </ul>
  );
};

function GenerateLink({ data }) {
  const Icon = data.icon;
  return (
    <li>
      <NavLink
        to={data.link}
        className={
          "pl-5 mr-3 flex items-center sidebar--menu-link mb-5 font-semibold text-[#676D7C] hover:text-[#FB7B80]"
        }
      >
        <Icon className="pl-3 mr-3" size={32} />
        <p>{data.text}</p>
      </NavLink>
      {data.gap && <hr className="w-[75%] mb-5 ml-[10%]" />}
    </li>
  );
}

export default Menu;
