import { Navigate, Outlet } from "react-router-dom";

const AuthProtected = ({ user,isLoggedIn, redirectPath = '/login', children }) => {
    if (!user || !isLoggedIn) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default AuthProtected;