const AuthTitles = (props) => {
    return (
        <>
            <div className={'auth-form--titles'}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src="logo.png" alt="logo" width="150" />
                    <h1 className="text-center text-2xl font-bold">{props.title}</h1>
                </div>
            </div>
        </>
    );
}

export default AuthTitles;