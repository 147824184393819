import { request } from "../../services/http-service";
import { successAlert, errorAlert } from "../../constants/helper";
import {
    LOGIN_SUCCESS, LOGIN_FAILURE,
    SIGNUP_SUCCESS, SIGNUP_FAILURE,
    LOGOUT_SUCCESS,
    AUTHENTICATE_USER_FAILURE,
    AUTHENTICATE_USER_SUCCESS,
} from '../../constants/action-types';

export const getCurrentUser = () => {
    return (dispatch) => {
        return request('auth/details', "POST", '', true).then((res) => {
            console.log(res);
            if (res && res.status === "ok") {
                dispatch({ type: AUTHENTICATE_USER_SUCCESS, data: res['user'] });
            } else {
                dispatch({ type: AUTHENTICATE_USER_FAILURE, payload: "User created Failure" });
                //localStorage.removeItem('token')
            }
        });
    };
};

export const loginUser = (loginData) => {
    return async (dispatch) => {
        try {
            const loginResponse = await request("auth/login", "post", loginData, false);
            console.log(loginResponse, "loginresponse")
            if (loginResponse && loginResponse.tokens) {
                console.log(loginResponse.tokens.access.token, "loginResponse.tokens.access.token")
                localStorage.setItem("token", loginResponse.tokens.access.token);
                localStorage.setItem("refresh", loginResponse.tokens.refresh.token);
                localStorage.setItem("user", JSON.stringify(loginResponse.user));
                
                dispatch({ type: LOGIN_SUCCESS });
                dispatch(getCurrentUser());
                successAlert('User login Successfully')
            }
            else {
                dispatch({ type: LOGIN_FAILURE });
                errorAlert(loginResponse.errorMessage)
            }
        } catch (error) {
            console.log(error);
            dispatch({ type: LOGIN_FAILURE, payload: error });
        }
    };
};

export const signupUser = (signupData) => {
    console.log("signupData")
    console.log(signupData)
    return async (dispatch) => {
        try {
            const d = {
                name: signupData.name,
                email: signupData.email,
                password: signupData.password,
            };
            const signupResponse = await request("auth/register", "post", d, false);
            console.log(signupResponse, "signupResponse")
            if (signupResponse && signupResponse.tokens) {
                localStorage.setItem("token", signupResponse.tokens.access.token);
                localStorage.setItem("refresh", signupResponse.tokens.refresh.token);
                dispatch({ type: SIGNUP_SUCCESS });
                dispatch(getCurrentUser());
                successAlert('User signup Successfully')
            }
            else {
                dispatch({ type: SIGNUP_FAILURE });
                errorAlert(signupResponse.errorMessage)
            }
        } catch (error) {
            console.log(error);
            dispatch({ type: SIGNUP_FAILURE, payload: error });
        }
    };
};

export const logoutAction = () => {
    return (dispatch) => {
        dispatch({
            type: LOGOUT_SUCCESS,
        });
        localStorage.removeItem("token");
        successAlert('User logout Successfully')
    };
};
