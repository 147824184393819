import React from "react";
import './App.css';
import './Fonts.css';

import AppRoutes from "./routes";

const App = () => {
	return (
		<>
			<AppRoutes />
		</>
	);
};

export default App;
